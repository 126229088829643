import React, { memo } from 'react'
import styled from '@emotion/styled'

import {
  CSS_ALIGN_ITEMS_CENTER,
  CSS_GRID,
  CSS_GRID_COLUMN,
  CSS_JUSTIFY_CONTENT_CENTER
} from '../../styles/shared'

const BWPoweredByQb = ({ qbxLogo }) => {
  return (
    <PoweredByContainer data-testid='bw-powered-by-qb'>
      <QbIconContainer src={qbxLogo} alt='Powered By' />
    </PoweredByContainer>
  )
}

export default memo(BWPoweredByQb)

const PoweredByContainer = styled.div`
  ${CSS_GRID};
  ${CSS_GRID_COLUMN};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  ${CSS_ALIGN_ITEMS_CENTER};
  gap: 5px;
  padding-top: 15px;
  padding-bottom: 10px;
`

const QbIconContainer = styled.img`
  height: 24px;
  margin-top: 1px;
`
