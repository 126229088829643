import {
  faIcons,
  faCoins,
  faGiftCard,
  faCoinBlank,
  faBagsShopping,
  faExchange,
  faWallet
} from '@fortawesome/pro-light-svg-icons'
import { BW_COLOR_MAIN, BW_LABEL_TEXT } from 'src/libs/qb-brand-web-components'

import { ERROR_TEXT, LABEL_TEXT, MESSAGE_TEXT } from '../../constants/messages'
import { buildFontFace } from './fonts/OpenSans/buildFontFace'
import { QIIBEE_PRIVACY_POLICY_URL } from '../../constants/externalUrls'
import { EARN_REWARD_OPTION } from '../../constants/pointsExchange'
import { LOG_IN_URL } from '../../constants/navigation'

const customConfig = {
  customCss: {},
  fontFace: buildFontFace(),
  fontFamily: 'OpenSans',
  rewardCodeRule: {
    min: 9,
    max: 9
  },
  nftsIcon: faIcons,
  nftIcon: faIcons,
  iconColor: BW_COLOR_MAIN,
  pointsLabel: 'common.points',
  exchangeIcon: faCoins,
  coinIcon: faCoinBlank,
  coinsIcon: faCoinBlank,
  shopBagIcon: faBagsShopping,
  transactionsIcon: faExchange,
  cashbackIcon: faWallet,
  exchangeText: LABEL_TEXT.EXCHANGE_POINTS,
  exchangeButtonText: LABEL_TEXT.EXCHANGE_POINTS,
  exchangeYourText: LABEL_TEXT.EXCHANGE_YOUR_POINTS,
  connectedAccountsLabel: 'exchange.connected-accounts',
  allTransactionsLabel: BW_LABEL_TEXT.ALL_TRANSACTIONS,
  allYourTransactionsLabel: BW_LABEL_TEXT.ALL_YOUR_TRANSACTIONS,
  loginPageTitle: 'login.log-in',
  termsAcceptPageTitle: BW_LABEL_TEXT.TERMS,
  rewardFromCodeFailedDesc: 'error.points-from-code-failed',
  howToEarnLabel: 'reward.how-to-earn-rewards',
  howManyPointsYouEarnLabel: 'reward.how-many-points-you-earn',
  receiveYourRewardLabel: 'reward.receive-your-reward',
  enterRewardCodeLabel: 'reward.enter-reward-code',
  overviewEnterRewardCodeLabel: 'reward.enter-reward-code',
  enterYourRewardCodeLabel: 'reward.enter-your-reward-code',
  rewardCodeUsedLabel: 'message.reward-code-used',
  tryAgainLabel: 'common.try-again',
  pleaseContactUsLabel: 'user-account.please-contact-us',
  receiveRewardLabel: 'reward.receive-reward',
  youEarnedItTitle: 'overview.you-earned-it',
  receivedPointsDesc: 'message.received-points',
  receivedPointsButtonLabel: 'common.awesome',
  receiveConfirmDesc: MESSAGE_TEXT.RECEIVE_POINTS,
  pointsOverviewLabel: 'nav-header.points-overview',
  redeemPointsLinkLabel: LABEL_TEXT.REDEEM_POINTS,
  redeemPointsLabel: LABEL_TEXT.REDEEM_POINTS,
  redeemYourPointsLabel: 'redeem.redeem-your-points',
  redeemPointsConfirmLabel: LABEL_TEXT.REDEEM_POINTS,
  redeemPointsDescLabel: 'message.redeem-points-desc',
  loginPageDescLabel: 'login.view-your-earned-points',
  manageAccounts: 'exchange.manage-accounts',
  manageConnectedAccounts: 'exchange.manage-connected-accounts',
  connectAccount: 'exchange.connect-account',
  needPointsForGiftCard: 'error.need-points-for-gift-card',
  youGotItLabel: 'redeem.you-got-it',
  sentEmailForCouponCode: 'message.sent-email-for-coupon-code',
  receiveGiftsLabel: 'common.awesome',
  accountSaveSuccessLabel: 'common.awesome',
  nftReceivedTitle: 'common.congratulations',
  incorrectRewardCodeLabel: 'error.incorrect-reward-code',
  selectProgramToExchange: MESSAGE_TEXT.SELECT_PROGRAM_TO_EXCHANGE,
  giftCardIcon: faGiftCard,
  coeCardIcon: faGiftCard,
  oopsLabel: 'common.oops',
  goBackLabel: 'common.go-back',
  continueLabel: 'common.continue',
  acceptPrivacyPolicy: LABEL_TEXT.ACCEPT_PRIVACY_POLICY,
  resetItLabel: 'login.reset-it',
  forgotPasswordLabel: 'forgot-password.enter-your-email-for-reset-link',
  signWithSocialLabel: 'forgot-password.signed-up-with-social-account',
  resetPasswordLabel: 'reset-password.reset-password',
  youDidItLabel: 'reset-password.you-did-it',
  goToLoginLabel: 'reset-password.go-to-login',
  checkYourEmailLabel: 'sign-up.check-your-email-inbox',
  youAreVerifiedLabel: 'sign-up.you-are-verified',
  youSuccessfullyVerifiedLabel: 'sign-up.you-successfully-verified-email',
  accountCreationFailedLabel: 'sign-up.account-creation-fail',
  couldNotFindPageLabel:
    'page-not-found.could-not-find-what-you-are-looking-for',
  viewQrCodeLabel: 'user-account.view-qr-code',
  accountNumberQrCodeLabel: 'user-account.account-number-qr-code',
  scanQrCodeDescLabel: 'user-account.scan-qr-code-desc',
  deleteAccountLabel: 'user-account.delete-account',
  noRedemptionOptionLabel: 'redeem.no-redemption-options-available',
  codeUsedLabel: 'overview.code-used',
  eventTypeLabel: 'transactions.event-type',
  forgotPasswordSuccessLabel: MESSAGE_TEXT.FORGOT_PASSWORD_SUCCESS,
  passwordResetSuccessLabel: 'message.password-reset-success',
  rewardCodeAlreadyExpired: 'message.reward-code-already-expired',
  emailAlreadyExistsLabel: ERROR_TEXT.EMAIL_ALREADY_EXISTS,
  triedTooManyTimesLabel: 'error.tried-too-many-times',
  scanCodeAndReceivePointsLabel: 'qr-code.scan-code-and-receive-points',
  scanQrCodeConnectAccountLabel: 'exchange.scan-qr-code-connect-account',
  viewShopLabel: 'exchange.view-shop',
  welcomeToShopLabel: 'exchange.welcome-to-shop',
  yourPreviousSubmissionsLabel: 'surveys.your-previous-submissions',
  privacyPolicyLabel: LABEL_TEXT.QIIBEE_PRIVACY_POLICY,
  privacyPolicyUrl: QIIBEE_PRIVACY_POLICY_URL,
  cancelLabel: 'common.cancel',
  receiveFollowingRewardsLabel: 'exchange.receive-following-reward',
  carefullyReadTermsLabel: 'sign-up.carefully-read-terms',
  signupButtonLabel: 'sign-up.sign-up',
  footerColor: BW_COLOR_MAIN,
  exploreMoreRewardsLabel: 'redeem.explore-more-rewards',
  resendVerificationEmailLabel: 'common.resend-verification-email',
  buildRedeemWebsiteUrl: (url) => url,
  currencyDelimiter: "'",
  buyPointsDesc: 'buy-points.title',
  getCashbackLabel: 'cashback.get-cashback',
  cashbackDesc: 'cashback.title',
  cashbackFromLabel: 'cashback.cashback-from',
  rewardOptionsOrder: [
    EARN_REWARD_OPTION.BUY_POINTS,
    EARN_REWARD_OPTION.CASHBACK
  ],
  myAccountLabel: 'user-account.my-account',
  accountNumberLabel: 'exchange.account-number',
  accountNumberCopiedLabel: 'message.account-number-copied',
  countrySettingsDescLabel: 'country-settings.country-settings-desc',
  noTransactionsLabel: 'transactions.no-transactions-yet',
  unsubscribeMessageLabel: 'user-account.unsubscribe-text',
  openNftLinkLabel: 'common.open-nft-link',
  nftRedeemedConfirmationLabel: 'redeem.nft-redeemed-confirmation',
  transferNftLabel: 'nft.transfer-nft',
  transferNftDescLabel: 'nft.transfer-nft-desc',
  transferYourNftLabel: 'nft.transfer-your-nft',
  sellNftLabel: 'nft.sell-nft',
  sellNftDescLabel: 'nft.sell-nft-desc',
  sellYourNftLabel: 'nft.sell-your-nft',
  stopNftSaleDescLabel: 'nft.stop-sale-desc',
  nftRewardLabel: 'nft.nft-reward',
  nftTransferLabel: 'nft.nft-transfer',
  nftSaleLabel: 'nft.nft-sale',
  nftSaleFeeLabel: 'nft.nft-sale-fee',
  nftPurchaseLabel: 'nft.nft-purchase',
  nftCreditLabel: 'nft.nft-credit',
  dateFormatLabel: BW_LABEL_TEXT.DATE_TIME,
  getPredefinedCashbackBrandsPath: '/get-top-stores',
  defaultBeforeLoginPath: LOG_IN_URL,
  signUpLabel: 'sign-up.sign-up',
  signUpDescLabel: 'sign-up.start-earning-your-points'
}

export default customConfig
