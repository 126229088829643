import React, { memo } from 'react'
import {
  BWPanelHeader,
  BWInput,
  BWPanelContent,
  emailRule,
  requiredRule,
  passwordRule
} from 'src/libs/qb-brand-web-components'

import { SIGNUP_PROFILE_DISPATCH } from '../../store/reducers/auth/signup'
import LoginLinkText from '../shared/loginLinkText/LoginLinkText'
import Button from '../shared/button/Button'
import { LABEL_TEXT } from '../../constants/messages'
import SocialMediaWrapper from 'wrappers/SocialMediaWrapper'
import Panel from '../shared/panel/Panel'
import customConfig from 'customConfig'

const SignupForm = ({
  onFormSubmit,
  dispatchAction,
  signupProfile: { error, firstName, lastName, email, password, isProcessing },
  onSocialMediaLogin,
  t
}) => {
  const title = t(customConfig.signUpLabel)
  const errorEmail = t(error[SIGNUP_PROFILE_DISPATCH.EMAIL])
  return (
    <Panel onFormSubmit={onFormSubmit} as='form' data-testid='signup'>
      <BWPanelHeader
        title={title}
        description={t(customConfig.signUpDescLabel)}
      />
      <BWPanelContent>
        <SocialMediaWrapper
          label={LABEL_TEXT.SIGNUP}
          t={t}
          onSocialMediaLogin={onSocialMediaLogin}
        />
        <BWInput
          label={t('common.first-name')}
          error={t(error[SIGNUP_PROFILE_DISPATCH.FIRST_NAME])}
          value={firstName}
          validate={[requiredRule]}
          onChange={(value) =>
            dispatchAction(SIGNUP_PROFILE_DISPATCH.FIRST_NAME, value)
          }
        />
        <BWInput
          label={t('common.last-name')}
          error={t(error[SIGNUP_PROFILE_DISPATCH.LAST_NAME])}
          value={lastName}
          validate={[requiredRule]}
          onChange={(value) =>
            dispatchAction(SIGNUP_PROFILE_DISPATCH.LAST_NAME, value)
          }
        />
        <BWInput
          type='email'
          label={t('common.email-address')}
          error={errorEmail}
          value={email}
          validate={[requiredRule, emailRule]}
          onChange={(value) =>
            dispatchAction(SIGNUP_PROFILE_DISPATCH.EMAIL, value)
          }
        />
        <BWInput
          label={t('common.password')}
          eyePassword
          error={t(error[SIGNUP_PROFILE_DISPATCH.PASSWORD])}
          value={password}
          validate={[requiredRule, passwordRule]}
          onChange={(value) =>
            dispatchAction(SIGNUP_PROFILE_DISPATCH.PASSWORD, value)
          }
        />
      </BWPanelContent>
      <Button
        label={t(customConfig.signupButtonLabel)}
        type='submit'
        disabled={isProcessing}
      />
      <LoginLinkText t={t} />
    </Panel>
  )
}

export default memo(SignupForm)
