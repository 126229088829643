import brandConfig from 'brandConfig'

const isRedemptionFulfillmentEnabled = () => {
  return brandConfig.features?.redemptionFulfillment
}

const isRewardsAvailable = () =>
  !brandConfig.features.disablePoints &&
  !brandConfig.features.disableRedemptionShop

export { isRedemptionFulfillmentEnabled, isRewardsAvailable }
